define('croatianchurch/models/podcast', ['exports', 'ember-data', 'moment'], function (exports, _emberData, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    const { attr } = _emberData.default;
    const { computed } = Ember;

    exports.default = _emberData.default.Model.extend({
        title: attr('string'),
        date: attr('date'),
        link: attr('string'),
        episode: attr('number'),
        linkUpload: attr('string'),

        prettyDate: computed('date', function () {
            return (0, _moment.default)(this.get('date')).format('DD MMMM YYYY');
        })
    });
});