define('croatianchurch/controllers/notification/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const { computed } = Ember;

  exports.default = Ember.Controller.extend({
    columns: computed(function () {
      return [{
        label: 'Title',
        valuePath: 'title'
      }, {
        label: 'Message',
        valuePath: 'message'
      }];
    }),

    actions: {
      transitionToDetail(row) {
        this.transitionToRoute('notification.show', row.get('content'));
      }
    }
  });
});