define('croatianchurch/components/task-options', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      onClose(shouldClose) {
        if (this.get('canClose')) {
          this.toggleProperty('canClose');
          return true;
        }

        return false;
      },

      toggleCompletion(task) {
        this.toggleProperty('row.content.isCompleted');

        if (this.get('row.content.isCompleted') && !this.get('row.content.completedDate')) {
          this.set('row.content.completedDate', new Date());
        }
      },

      async save(dropdown) {
        await this.get('row.content').save();

        this.set('canClose', true);
        dropdown.actions.close();
      },

      rollback(dropdown) {
        this.get('row.content').rollbackAttributes();

        this.set('canClose', true);
        dropdown.actions.close();
      }
    }
  });
});