define('croatianchurch/components/upload-area', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const { inject } = Ember;

  exports.default = Ember.Component.extend({

    progress: 0,
    showProgress: false,

    firebaseApp: inject.service(),
    notifications: inject.service('notification-messages'),

    actions: {
      uploadDocument([file]) {
        const firebase = this.get('firebaseApp');
        var storageRef = firebase.storage().ref();
        const fileParts = file.name.split('.');
        const extension = fileParts[fileParts.length - 1] || 'pdf';
        var documentRef = storageRef.child('documents/' + this.get('path') + `.${extension}`);
        const uploadTask = documentRef.put(file);
        uploadTask.on('state_changed', snapshot => {
          var progress = snapshot.bytesTransferred / snapshot.totalBytes * 100;
          this.set('progress', progress / 100);
          this.set('showProgress', true);
          switch (snapshot.state) {
            case 'paused':
              break;
            case 'running':
              break;
          }
        }, error => {
          this.get('notifications').error(`There was an error uploading file: ${error.code}`);
        }, () => {
          const { downloadURL } = uploadTask.snapshot;
          this.get('afterUpload')(downloadURL);
          this.set('showProgress', false);
          this.get('notifications').success(`File uploaded successfully`, { autoClear: true, clearDuration: 2000 });
        });
      }
    }
  });
});