define('croatianchurch/controllers/config/index', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    const { computed } = Ember;

    exports.default = Ember.Controller.extend({
        columns: computed(function () {
            return [{
                label: 'Priest Name',
                valuePath: 'name'
            }, {
                label: 'About',
                valuePath: 'about'
            }, {
                label: 'Email',
                valuePath: 'email',
                width: '150px'
            }, {
                label: 'Phone Number',
                valuePath: 'number'
            }, {
                label: 'Mobile',
                valuePath: 'mobile'
            }, {
                label: 'Live Radio URL',
                valuePath: 'liveradio',
                width: '200px'
            }, {
                label: 'Address',
                valuePath: 'location'
            }];
        }),
        actions: {
            transitionToDetail(row) {
                this.transitionToRoute('config.show', row.get('content'));
            }
        }
    });
});