define('croatianchurch/components/sortable-table', ['exports', 'croatianchurch/mixins/table-common', 'ember-concurrency'], function (exports, _tableCommon, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const {
    computed
  } = Ember;

  exports.default = Ember.Component.extend(_tableCommon.default, {
    query: '',

    // No need for `enableSync` here
    enableSync: false,

    isLoading: computed.or('fetchRecords.isRunning', 'setRows.isRunning').readOnly(),

    // Sort Logic
    sortedModel: computed.sort('model', 'sortBy').readOnly(),
    sortBy: computed('dir', 'sort', function () {
      return [`${this.get('sort')}:${this.get('dir')}`];
    }).readOnly(),

    fetchRecords: (0, _emberConcurrency.task)(function* () {
      yield this.get('filterAndSortModel').perform();
    }).on('init'),

    setRows: (0, _emberConcurrency.task)(function* (rows) {
      this.get('table').setRows([]);
      this.get('table').setRows(rows);
    }).restartable(),

    filterAndSortModel: (0, _emberConcurrency.task)(function* (debounceMs = 200) {
      yield (0, _emberConcurrency.timeout)(debounceMs); // debounce

      let query = this.get('query');
      let model = this.get('sortedModel');
      let valuePath = this.get('selectedFilter.valuePath');
      let result = model;

      if (query !== '') {
        result = model.filter(m => {
          return m.get(valuePath).toLowerCase().includes(query.toLowerCase());
        });
      }

      yield this.get('setRows').perform(result);
    }).restartable(),

    actions: {
      onColumnClick(column) {
        if (column.sorted) {
          this.setProperties({
            dir: column.ascending ? 'asc' : 'desc',
            sort: column.get('valuePath')
          });

          this.get('filterAndSortModel').perform(0);
        }
      },

      onSearchChange() {
        this.get('filterAndSortModel').perform();
      }
    }
  });
});