define('croatianchurch/initializers/notifications', ['exports', 'ember-cli-notifications/services/notification-messages-service'], function (exports, _notificationMessagesService) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = {
        name: 'notification-messages-service',

        initialize() {
            let application = arguments[1] || arguments[0];
            if (Ember.Service) {
                application.register('service:notification-messages', _notificationMessagesService.default);
                application.inject('component:notification-container', 'notifications', 'service:notification-messages');
                application.inject('component:notification-message', 'notifications', 'service:notification-messages');
                return;
            }
            application.register('notification-messages:service', _notificationMessagesService.default);

            ['controller', 'component', 'route', 'router', 'service'].forEach(injectionTarget => {
                application.inject(injectionTarget, 'notifications', 'notification-messages:service');
            });
        }
    };
});