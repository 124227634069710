define('croatianchurch/routes/document/show', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model({ id }) {
      if (id === 'new') {
        return this.store.createRecord('document', {});
      }

      return this.store.findRecord('document', id);
    },

    actions: {
      willTransition(transition) {
        this.get('controller').willTransition(transition);
      }
    }
  });
});