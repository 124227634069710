define('croatianchurch/controllers/business/show', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const { inject } = Ember;

  exports.default = Ember.Controller.extend({

    notifications: inject.service('notification-messages'),

    willTransition(transition) {
      let model = this.get('model');

      if (model.get('hasDirtyAttributes')) {
        // TODO: Put remodal here for prettier modal
        if (confirm("Business Directory has unsaved changes. Leaving the page will discard them. Continue?")) {
          model.rollbackAttributes();
        } else {
          transition.abort();
        }
      }
    },

    actions: {
      deleteItem() {
        this.$('.modal').modal();
      },

      deleteConfirmed() {
        const item = this.get('item');
        item.destroyRecord();
      },

      deleteBusiness(model) {

        if (confirm(`Delete business: ${model.get('name')}`)) {
          model.destroyRecord().then(() => {
            return this.get('model');
          }).then(() => {
            //TODO: add notification
            this.get('notifications').success('Deleted successfully!', { autoClear: true, clearDuration: 500 });
            this.transitionToRoute('business');
          }, error => {
            this.get('notifications').error(`There was an error deleting the task ${JSON.stringify(error)}`);
          });
        }
      },

      saveBusiness() {
        this.get('model').save().then(() => {
          this.get('notifications').success('Saved successfully!', { autoClear: true, clearDuration: 500 });
          this.transitionToRoute('business');
        }, error => {
          this.get('notifications').error(`There was an error saving the event ${JSON.stringify(error)}`);
        });
      },

      setBusinessDocumentLink(model, url, fileName) {
        if (model.set) {
          model.set('fileName', fileName);
          model.set('image', url);
          model.save();
        } else {
          model.image = url;
          model.fileName = fileName;
        }
      }
    }
  });
});