define('croatianchurch/models/business', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    const { attr } = _emberData.default;

    exports.default = _emberData.default.Model.extend({
        name: attr('string'),
        about: attr('string'),
        description: attr('string'),
        email: attr('string'),
        location: attr('string'),
        number: attr('string'),
        web: attr('string'),
        image: attr('string')
    });
});