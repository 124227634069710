define('croatianchurch/controllers/business/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const { computed } = Ember;

  exports.default = Ember.Controller.extend({
    columns: computed(function () {
      return [{
        label: 'Business Name',
        valuePath: 'name'
      }, {
        label: 'Description',
        valuePath: 'description'
      }, {
        label: 'Email',
        valuePath: 'email',
        width: '200px'
      }, {
        label: 'Number',
        valuePath: 'number'
      }, {
        label: 'Website',
        valuePath: 'web',
        width: '240px'

      }, {
        label: 'location',
        valuePath: 'location'
      }, {
        label: 'Image',
        valuePath: 'image',
        sortable: false,
        cellComponent: 'task-options'
      }];
    }),
    actions: {
      transitionToDetail(row) {
        this.transitionToRoute('business.show', row.get('content'));
      }
    }
  });
});