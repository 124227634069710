define('croatianchurch/mixins/table-common', ['exports', 'ember-light-table', 'ember-concurrency'], function (exports, _emberLightTable, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const {
    inject,
    isEmpty,
    computed,
    observer
  } = Ember;

  exports.default = Ember.Mixin.create({
    store: inject.service(),

    page: 0,
    limit: 20,
    dir: 'desc',
    sort: 'episode',

    isLoading: computed.oneWay('fetchRecords.isRunning'),
    canLoadMore: true,
    enableSync: true,

    model: null,
    meta: null,
    columns: null,
    table: null,

    init() {
      this._super(...arguments);

      this.setupTable();
    },

    setupTable() {
      let table = new _emberLightTable.default(this.get('columns'), this.get('model'), { enableSync: this.get('enableSync') });
      let sortColumn = table.get('allColumns').findBy('valuePath', this.get('sort'));

      // Setup initial sort column
      if (sortColumn) {
        sortColumn.set('sorted', true);
      }

      this.set('table', table);
    },

    tableReser: observer('model.[]', function () {
      this.setupTable();
    }),

    actions: {
      onScrolledToBottom() {
        if (this.get('canLoadMore')) {
          this.incrementProperty('page');
          this.get('fetchRecords').perform();
        }
      },

      onColumnClick(column) {
        if (column.sorted) {
          this.setProperties({
            dir: column.ascending ? 'asc' : 'desc',
            sort: column.get('valuePath'),
            canLoadMore: true,
            page: 0
          });
          this.get('model').clear();
        }
      }
    }
  });
});