define('croatianchurch/models/notification', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    const { attr } = _emberData.default;

    exports.default = _emberData.default.Model.extend({
        title: attr('string'),
        message: attr('string')
    });
});