define('croatianchurch/components/task-row', ['exports', 'ember-light-table/components/lt-row'], function (exports, _ltRow) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const {
    computed,
    String: { htmlSafe }
  } = Ember;

  exports.default = _ltRow.default.extend({
    classNameBindings: ['row.content.isCompleted:completed']
  });
});